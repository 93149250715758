/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { css } from "@emotion/core"

import Footer from "./footer"

export const desktopLayoutWidth = 620

const Layout = ({ ccLicense = true, children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lora:400,400i|Muli"
          rel="stylesheet"
        />
      </Helmet>
      <div
        css={css`
          font-family: Muli;
          max-width: ${desktopLayoutWidth}px;
          margin: 0 auto;
          color: #222;
        `}
      >
        <main>{children}</main>
        {ccLicense && <Footer />}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
