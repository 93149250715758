import React, { useState } from "react"
import { css } from "@emotion/core"
import { ParentSize } from "@visx/responsive"

import Layout, { desktopLayoutWidth } from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import { BlogSubscribeToEmail } from "../components/blog-post"

const contentMargin = 15
const desktopContentWidth = desktopLayoutWidth - contentMargin * 2

function useClamped(initialValue, minValue, maxValue) {
  const [value, setValue] = useState(initialValue)
  return [
    value,
    newValue => setValue(Math.min(maxValue, Math.max(minValue, newValue))),
  ]
}

const btnWidth = 100
const desktopBtnCSS = css`
  width: ${btnWidth}px;
  height: 30px;
  background: #4d96ff;
  color: #eee;
  border-radius: 3px;
  border: none;
  text-shadow: 0px 1px 1px rgba(120, 120, 150, 0.9);
  box-shadow: 0px 2px 4px #aaa;
  user-select: none;
  touch-action: manipulation;
  &:hover {
    background: #5da6ff;
  }
  &:active {
    background: #3993fc;
  }
`
const mobileBtnCSS = css`
  ${desktopBtnCSS}
  height: 40px;
  font-size: 16px;
`

const Mark = ({ x, y, r }) => (
  <g>
    <line
      x1={x - r}
      y1={y - r}
      x2={x + r}
      y2={y + r}
      stroke="#FF6B6B"
      strokeWidth={3}
    />
    <line
      x1={x + r}
      y1={y - r}
      x2={x - r}
      y2={y + r}
      stroke="#FF6B6B"
      strokeWidth={3}
    />
  </g>
)

const OneDimensionalRelationship = ({ outerWidth, maxOuterWidth }) => {
  outerWidth = Math.min(outerWidth, maxOuterWidth)
  const height = 30

  const y = height / 2
  const mx = 10

  const chartWidth = outerWidth - btnWidth * 2
  const width = chartWidth - mx * 2

  const maxVal = 100
  const [val, set] = useClamped(maxVal / 2, 0, maxVal)

  const x = width * (val / maxVal)

  if (!outerWidth) {
    return null
  }

  const btnCSS = outerWidth < maxOuterWidth ? mobileBtnCSS : desktopBtnCSS

  return (
    <div
      css={css`
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 2rem;
      `}
    >
      <button css={btnCSS} onClick={() => set(val - 5)}>
        Be mean
      </button>
      <svg width={chartWidth} height={height}>
        <g transform={`translate(${mx} ${y})`}>
          <line x1={0} y1={0} x2={width} y2={0} stroke="#444" strokeWidth={1} />
          <Mark x={x} y={0} r={8} />
        </g>
      </svg>
      <button css={btnCSS} onClick={() => set(val + 5)}>
        Be nice
      </button>
    </div>
  )
}

const TwoDimensionalRelationship = ({ outerWidth, maxOuterWidth }) => {
  outerWidth = Math.min(maxOuterWidth, outerWidth)
  const m = 10
  const width = outerWidth - m * 2

  const maxValue = 100
  const [xValue, setXValue] = useClamped(0, 0, maxValue)
  const [yValue, setYValue] = useClamped(0, 0, maxValue)

  const x = width * (xValue / maxValue)
  const y = width - width * (yValue / maxValue)

  const btnCSS = outerWidth < maxOuterWidth ? mobileBtnCSS : desktopBtnCSS

  return (
    <div
      css={css`
        margin: 1.5rem auto 2rem;
        width: ${outerWidth}px;
      `}
    >
      <div
        css={css`
          margin-bottom: 5px;
        `}
      >
        <button css={btnCSS} onMouseDown={() => setYValue(yValue + 7)}>
          Be nice
        </button>
      </div>
      <svg width={outerWidth} height={outerWidth}>
        <g transform={`translate(${m} ${m})`}>
          <line x1={0} y1={0} x2={0} y2={width} stroke="#444" strokeWidth={1} />
          <line
            x1={0}
            y1={width}
            x2={width}
            y2={width}
            stroke="#444"
            strokeWidth={1}
          />
          <Mark y={y} x={x} r={8} />
        </g>
      </svg>
      <div style={{ textAlign: "right", width: outerWidth }}>
        <button css={btnCSS} onMouseDown={() => setXValue(xValue + 7)}>
          Be mean
        </button>
      </div>
    </div>
  )
}

export default () => (
  <Layout>
    <SEO title="Relationship space" description="The bell cannot be unrung." />
    <PageHeader />
    <div
      css={css`
        margin: 0 ${contentMargin}px;
      `}
    >
      <h1>Relationship space</h1>
      <p>
        As a child, I learned the idea of the “relationship balance”. I was
        taught that your relationship with everyone is like a bank account.
        Being nice to someone was like depositing money into that account. Being
        mean was like withdrawing.
      </p>
      <p>
        It's like an app that only has two buttons, “be mean” and “be nice”:
      </p>
      <ParentSize>
        {parent => (
          <OneDimensionalRelationship
            outerWidth={parent.width}
            maxOuterWidth={desktopContentWidth}
          />
        )}
      </ParentSize>
      <p>
        The nice thing about this model is, if you do a bunch of mean things,
        you can go back and make up for it by doing a bunch of nice things.
      </p>
      <p>
        But in truth, doing a nice thing doesn't “undo” a mean thing you did
        before: the memory of the mean thing still lingers. The past cannot be
        erased. Nice words and mean words, nice actions and mean actions, are
        not even directly comparable. So really you're not moving back and forth
        on a line, you're moving through a space, and you can only ever increase
        the amount of nice and mean you have done for (or to) someone:
      </p>
      <ParentSize>
        {parent => (
          <TwoDimensionalRelationship
            outerWidth={parent.width}
            maxOuterWidth={380}
          />
        )}
      </ParentSize>
      <p>
        This of course is an incomplete model of relationships. But it reminds
        me why I can't just cover up mean things I've done with nice things. As
        they say: the bell cannot be unrung.
      </p>
    </div>
    <BlogSubscribeToEmail />
  </Layout>
)
