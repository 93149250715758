import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import logoImg from "../images/noah-logo-borderless.svg"

export default ({ logoSpace = "50px" }) => (
  <header
    css={css`
      display: flex;
      margin: 60px 15px ${logoSpace};
    `}
  >
    <Link to="/">
      <img
        css={css`
          width: 200px;
        `}
        alt="logo"
        src={logoImg}
      />
    </Link>
  </header>
)
