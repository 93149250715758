import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import SEO from "../components/seo"
import Layout from "../components/layout"
import SubscribeToEmail from "./subscribe-to-email"
import PageHeader from "./page-header"
import "./blog-styles.css"

export default function BlogPost({ data }) {
  const post = data.markdownRemark

  if (post.frontmatter.previewImage) {
    var previewImage = post.frontmatter.previewImage.childImageSharp.fixed
  }

  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.desc}
        image={previewImage}
      />
      <PageHeader />
      <div className="blog-post">
        <h1>{post.frontmatter.title}</h1>
        <div className="meta">
          {post.frontmatter.date} ・ {post.frontmatter.loc}
        </div>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </div>
      <BlogSubscribeToEmail />
    </Layout>
  )
}

export const BlogSubscribeToEmail = () => (
  <SubscribeToEmail>
    <p
      css={css`
        margin-top: 0;
      `}
    >
      Thanks for reading! You can find more of my writing at{" "}
      <Link
        to="/"
        css={css`
          color: #5d27ff;
        `}
      >
        my homepage
      </Link>
      .
    </p>
    <p>Want to know when I write something new?</p>
  </SubscribeToEmail>
)

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        loc
        desc
        previewImage {
          childImageSharp {
            fixed(width: 1200) {
              src
              width
              height
            }
          }
        }
        date(formatString: "D MMMM YYYY")
      }
    }
  }
`
