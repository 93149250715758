import React from "react"

import { css } from "@emotion/core"

export default function SubscribeToEmail({ children }) {
  return (
    <form
      action="https://buttondown.email/api/emails/embed-subscribe/noahlt"
      method="post"
      target="popupwindow"
      onSubmit={e =>
        window.open("https://buttondown.email/noahlt", "popupwindow")
      }
      css={css`
        background-color: #f3f3f3;
        padding: 15px;
        margin-top: 40px;
      `}
    >
      {children}
      <div
        css={css`
          margin-top: 8px;
          @media (min-width: 600px) {
            display: flex;
            margin-right: 10px;
          }
        `}
      >
        <label htmlFor="bd-email">Enter your email:</label>
        <input
          type="email"
          name="email"
          id="bd-email"
          css={css`
            display: inline-block;
            font-family: Muli, sans-serif;
            font-size: 16px;
            @media (max-width: 600px) {
              margin-left: 0;
              display: block;
              width: 95%;
            }
            @media (min-width: 600px) {
              margin-left: 10px;
              flex-grow: 1;
            }
          `}
        />
        <input type="hidden" value="1" name="embed" />
        <input
          type="submit"
          value="Subscribe"
          css={css`
            font-family: Muli, sans-serif;
            font-size: 16px;
            background-color: #5d27ff;
            color: #eee;
            border-radius: 5px;
            border: none;
            -webkit-appearance: none;
            @media (max-width: 600px) {
              display: block;
              width: 100%;
              padding: 5px 0 8px;
            }
          `}
        />
      </div>
    </form>
  )
}
